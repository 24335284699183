<template>
  <div>
    <a-card title="DWS 上传日志 C">
      <template slot="extra">
        <a-space style="margin: -4px 0">
          <a-date-picker v-model="queryDate" @change="search" />
          <a-button @click="download">下载</a-button>
        </a-space>
      </template>
      <iframe :src="queryUrl" width="100%" height="600" style="border: none"></iframe>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      baseUrl: "/logs",
      queryUrl: "",
      queryDate: moment(),
    };
  },
  methods: {
    search() {
      const filename = `${this.queryDate.format("YYYY-MM-DD")}.log`;
      this.queryUrl = `${this.baseUrl}/${this.queryDate.format("YYYY-MM")}/dws_upload_c.${filename}`;
    },
    download() {
      const link = document.createElement("a");
      link.href = this.queryUrl;
      link.download = `dws_upload_c.${this.queryDate.format("YYYY-MM-DD")}.log`;

      // 触发点击事件
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style scoped></style>
